import { DjangoListResponse, DjangoResponse } from "@runners/interface"
import api, { METHOD } from "."
import {
  AdminNoticeDto,
  AdminNoticeListDto,
  AdminNoticeListParams,
  PostAdminNoticePayload,
  PatchAdminNoticePayload,
} from "./notice.type"

const noticeApi = {
  /**
   * 포스트 삭제
   */
  deletePost: async ({ noticeId }: { noticeId: number }) => {
    const { data } = await api(`/admin/notice/${noticeId}`, {
      method: METHOD.DELETE,
    })
    return data
  },
  /**
   * 공지 리스트 조회
   */
  getNoticeList: async (params: AdminNoticeListParams) => {
    const { data } = await api<DjangoListResponse<AdminNoticeListDto>>(`/admin/notices`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 공지 객체 조회
   */
  getNotice: async ({ noticeId }: { noticeId: number }) => {
    const { data } = await api<DjangoResponse<AdminNoticeDto>>(`/admin/notice/${noticeId}`, {
      method: METHOD.GET,
    })
    return data
  },

  /**
   * 공지 생성
   */
  postNotice: async ({ payload }: { payload: PostAdminNoticePayload }) => {
    const { data } = await api(`/admin/notice`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 공지 수정
   */
  patchNotice: async ({ noticeId, payload }: { noticeId: number; payload: PatchAdminNoticePayload }) => {
    const { data } = await api(`/admin/notice/${noticeId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },

  /**
   * 공지 선택 활성화
   */
  postNoticeActivate: async ({ notice_ids }: { notice_ids: number[] }) => {
    const { data } = await api(`/admin/notice/activate`, {
      method: METHOD.POST,
      data: { notice_ids },
    })
    return data
  },

  /**
   * 공지 선택 비활성화
   */
  postNoticeDeactivate: async ({ notice_ids }: { notice_ids: number[] }) => {
    const { data } = await api(`/admin/notice/deactivate`, {
      method: METHOD.POST,
      data: { notice_ids },
    })
    return data
  },
}

export default noticeApi
