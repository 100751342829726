import { Image } from "@runners/ui"
import { ErrorTriangleIcon, VideoIcon, XIcon } from "@/assets"
import { VideoUploadItem } from "./video-modal"
import VideoModalUploadStatus from "./video-modal-upload-status"

interface VideoModalListItemProps {
  index: number
  item: VideoUploadItem
  onRemove: () => void
}

const VideoModalListItem = (props: VideoModalListItemProps) => {
  const { item, index, onRemove } = props
  return (
    <div className="flex items-center gap-4 rounded-1 border border-gray-300 px-4 py-2">
      <p className="shrink-0 text-34 font-medium tabular-nums text-gray-600">{index + 1}</p>

      {item.isError && (
        <div className="flex h-12 w-[74px] shrink-0 items-center justify-center bg-gray-100">
          <ErrorTriangleIcon className="size-6 text-error-500" />
        </div>
      )}
      {item.isLoading && (
        <div className="flex h-12 w-[74px] shrink-0 items-center justify-center bg-gray-100">
          <VideoIcon className="h-[13.5px] w-[22.5px] text-gray-300" />
        </div>
      )}

      {!item.isError && !item.isLoading && (
        <Image size="768" className="h-12 w-[74px] shrink-0" src={item.thumbnail} alt={item.name} />
      )}

      <p className="line-clamp-1 w-full text-ellipsis text-14 text-gray-600">{item.name}</p>
      <VideoModalUploadStatus status={item.status} />

      <button className="group shrink-0 p-1" onClick={onRemove}>
        <XIcon className="size-5 text-gray-700 group-hover:text-gray-400" />
      </button>
    </div>
  )
}

export default VideoModalListItem
