import { useEffect, useMemo, useState } from "react"
import { statusOptionList } from "@runners/lib/constants"
import { toLocalePrice } from "@runners/lib/price"
import { Button } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import productQueryKey from "@/_api/_query-key/product"
import productLabelQueryKey from "@/_api/_query-key/product-label"
import { AdminProductListDto } from "@/_api/product.type"
import Image from "@/_components/image"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import FilterDropdown from "@/_components/table/filter-dropdown-table"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { useTableSearch } from "@/_hooks/use-table-search"
import StoreStatusBadge from "./_components/store-status-badge"
import { useGetProductLabelListQuery, useGetProductListQuery } from "./_hooks/use-get-query"
import {
  useProductActivateMutation,
  useProductDeactivateMutation,
  useProductStatusMutation,
  useProductLabelMutation,
} from "./_hooks/use-mutation"

type LabelType = "AGE" | "RELATIONSHIP" | "EVENT_CATEGORY" | "GENDER" | "SUGGESTION" | "RANKING"
interface Labels {
  ageLabel: number
  relationLabel: number
  eventCategoryLabel: number
  genderLabel: number
  suggestionLabel: number
  rankingLabel: number
}

const ProductPage = () => {
  const { Table, page, pageSize } = usePaginationTable<AdminProductListDto>()
  const { SearchInput, search } = useTableSearch()

  const {
    data: productList,
    isLoading,
    totalCount,
  } = useGetProductListQuery({
    page_size: pageSize,
    page,
    search,
  })
  const { data: labelData } = useGetProductLabelListQuery({})
  const qc = useQueryClient()

  const onSuccess = () => {
    qc.invalidateQueries({ queryKey: productQueryKey.productList({}) })
    alert("상태 변경 성공")
  }
  const labelOnSuccess = () => {
    qc.invalidateQueries({ queryKey: productLabelQueryKey.productLabelList({}) })
    alert("라벨저장성공")
  }

  const onError = (e: any) => {
    console.error(e)
    alert("error!")
  }

  const { mutate: activate } = useProductActivateMutation({ onSuccess, onError })
  const { mutate: deactivate } = useProductDeactivateMutation({ onSuccess, onError })
  const { mutate: statusChange } = useProductStatusMutation({ onSuccess, onError })
  const { mutate: saveLabel } = useProductLabelMutation({ onSuccess: labelOnSuccess, onError })

  const [selectedRows, setSelectedRows] = useState<AdminProductListDto[]>([])

  const labelDataType = (type: string) => {
    if (!labelData) return []
    return labelData.filter(label => label.type === type).map(label => ({ label: label.title, value: label.id }))
  }

  const defaultLabels = useMemo(() => {
    if (!labelData) return null
    return {
      ageLabel: labelDataType("AGE")[0]?.value,
      relationLabel: labelDataType("RELATIONSHIP")[0]?.value,
      eventCategoryLabel: labelDataType("EVENT_CATEGORY")[0]?.value,
      genderLabel: labelDataType("GENDER")[0]?.value,
      suggestionLabel: labelDataType("SUGGESTION")[0]?.value,
      rankingLabel: labelDataType("RANKING")[0]?.value,
    }
  }, [labelData])
  const [labels, setLabels] = useState<Labels>({
    ageLabel: 0,
    relationLabel: 0,
    eventCategoryLabel: 0,
    genderLabel: 0,
    suggestionLabel: 0,
    rankingLabel: 0,
  })

  useEffect(() => {
    if (defaultLabels) {
      setLabels(defaultLabels)
    }
  }, [defaultLabels])

  /**
   * 상품은 드롭다운이 좀 많습니다.
   */
  const dropdownList: TableDropdownList = [
    ...statusOptionList.map(v => ({
      label: `선택상품 ${v.label}`,
      fn: (ids: number[]) => statusChange({ product_ids: ids, status: v.value }),
    })),
    { label: "선택상품 활성화", fn: ids => activate({ product_ids: ids }) },
    { label: "선택상품 비활성화", fn: ids => deactivate({ product_ids: ids }) },
  ]

  const handleSaveLabels = () => {
    if (selectedRows.length === 0) {
      alert("상품을 선택해주세요.")
      return
    }
    const productLabels = [
      labels.ageLabel,
      labels.relationLabel,
      labels.eventCategoryLabel,
      labels.genderLabel,
      labels.suggestionLabel,
      labels.rankingLabel,
    ]

    saveLabel({
      product_ids: selectedRows.map(row => row.id),
      product_labels: productLabels,
    })
  }

  const labelTypes: { type: LabelType; description: string; labelKey: keyof Labels }[] = [
    { type: "AGE", description: "나이", labelKey: "ageLabel" },
    { type: "RELATIONSHIP", description: "관계", labelKey: "relationLabel" },
    { type: "EVENT_CATEGORY", description: "유형", labelKey: "eventCategoryLabel" },
    { type: "GENDER", description: "성별", labelKey: "genderLabel" },
    { type: "SUGGESTION", description: "카테고리", labelKey: "suggestionLabel" },
    { type: "RANKING", description: "랭킹", labelKey: "rankingLabel" },
  ]

  return (
    <AdminPageLayout totalCount={totalCount}>
      <SearchInput
        className="max-w-[500px]"
        label="검색 필터"
        description="가능한 필드: 스토어"
        placeholder="필터링할 검색어 입력"
      />
      <div className="flex gap-4 border border-y-2 py-2">
        <div className="mr-8 flex items-center">상품 라벨</div>
        {labelTypes.map(({ type, description, labelKey }) => (
          <FilterDropdown
            key={type}
            options={labelDataType(type)}
            selectedOption={labels[labelKey]}
            setSelectedOption={value => setLabels(prev => ({ ...prev, [labelKey]: Number(value) }))}
            description={description}
            className="cursor-pointer"
          />
        ))}

        <Button size="40" className="my-auto cursor-pointer" onClick={handleSaveLabels}>
          라벨 저장
        </Button>
      </div>

      <Table
        totalCount={totalCount}
        isLoading={isLoading}
        data={productList}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        tableDropdownList={dropdownList}
        // href={value => {
        //   return `/service/post/${value?.id}`
        // }}
        className="min-w-[1600px]"
        title={[
          "ID",
          "상태",
          "이미지",
          "제품명",
          "스토어",
          "카테고리",
          "가격",
          "판매수",
          "재고",
          "작성자",
          "등록일",
          "최종 수정일",
        ]}
        widths={[2, 2, 2, 5, 3, 3, 3, 2, 2, 3, 2, 2]}
        values={{
          ID: value => value?.id,
          상태: value => (
            <div className="flex items-center justify-center">
              <StoreStatusBadge
                status={value?.status as (typeof statusOptionList)[number]["value"]}
                is_active={value?.is_active}
              />
            </div>
          ),
          이미지: value => <Image src={value?.image_url} size="768" />,
          제품명: value => value?.title,
          스토어: value => value?.store_title,
          카테고리: value => value?.product_category?.title || "-",
          가격: value => <p className="text-end">{toLocalePrice(value?.origin_price)}원</p>,
          판매수: value => value?.sold_count,
          재고: value => value?.stock_count,
          작성자: value => value?.user_email,
          등록일: value => dayjs(value?.created).format("YY.MM.DD"),
          "최종 수정일": value => dayjs(value?.modified).format("YY.MM.DD"),
        }}
      />
    </AdminPageLayout>
  )
}

export default ProductPage
