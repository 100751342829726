import React, { useEffect, useRef, useState } from "react"
import { cn } from "@runners/lib"
import { Button } from "@runners/ui"

interface TimePickerModalProps {
  initialValue?: string
  onClick: (time: string) => void
}

interface Time {
  hour: number
  minute: number
}

const _formatTimeToJSON = (time?: string): Partial<Time> => {
  if (!time) return { hour: 0, minute: 0 }

  const [hourStr, minuteStr] = time.split(":")
  const hour = parseInt(hourStr, 10)
  const minute = parseInt(minuteStr, 10)

  return { hour, minute }
}

const _jsonToTimeString = (time: Partial<Time>): string => {
  const hourStr = String(time.hour || 0).padStart(2, "0")
  const minuteStr = String(time.minute || 0).padStart(2, "0")

  return `${hourStr}:${minuteStr}`
}

const TimePickerModal = ({ initialValue, onClick }: TimePickerModalProps) => {
  const [time, setTime] = useState<Partial<Time>>(_formatTimeToJSON(initialValue))
  const hourRef = useRef<HTMLParagraphElement>(null)
  const minuteRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (hourRef.current) hourRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    if (minuteRef.current) minuteRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
  }, [time.hour, time.minute])

  const handleHourChange = (hour: number) => setTime(prev => ({ ...prev, hour }))
  const handleMinuteChange = (minute: number) => setTime(prev => ({ ...prev, minute }))

  const handleConfirm = () => onClick(_jsonToTimeString(time))

  const handleNowClick = () => {
    const now = new Date()
    const currentHour = now.getHours()
    const currentMinute = now.getMinutes()
    setTime({ hour: currentHour, minute: currentMinute })
    onClick(_jsonToTimeString({ hour: currentHour, minute: currentMinute }))
  }

  return (
    <div className="flex flex-col items-center rounded-lg bg-white p-4 shadow-md">
      {/* Time Selector */}
      <div className="flex flex-row gap-2 rounded-2 border-b-0 border-gray-300 bg-white py-2 md:border [&>*]:max-h-[25vh] [&>*]:overflow-scroll [&>*]:scrollbar-hide [&>*]:md:max-h-[240px]">
        <div className="flex flex-col overflow-y-auto">
          {Array.from({ length: 24 }).map((_, i) => (
            <p
              key={i}
              ref={time.hour === i ? hourRef : undefined}
              onClick={() => handleHourChange(i)}
              className={cn("cursor-pointer px-9 py-2 font-medium text-gray-700", {
                "bg-gray-100 font-bold text-primary-700": time.hour === i,
              })}
            >
              {String(i).padStart(2, "0")}
            </p>
          ))}
        </div>
        <div className="flex flex-col overflow-y-auto">
          {Array.from({ length: 60 }).map((_, i) => (
            <p
              key={i}
              ref={time.minute === i ? minuteRef : undefined}
              onClick={() => handleMinuteChange(i)}
              className={cn("cursor-pointer px-9 py-2 font-medium text-gray-700", {
                "bg-gray-100 font-bold text-primary-700": time.minute === i,
              })}
            >
              {String(i).padStart(2, "0")}
            </p>
          ))}
        </div>
      </div>

      {/* Now and Confirm Buttons */}
      <div className="mt-4 flex w-full justify-between">
        <Button size="48" className="mr-2 w-1/2 bg-none" onClick={handleNowClick}>
          NOW
        </Button>
        <Button size="48" className="w-1/2" color="primary" onClick={handleConfirm}>
          확인
        </Button>
      </div>
    </div>
  )
}

export default TimePickerModal
