import React, { useEffect, useMemo, useRef, useState } from "react"
import { useClickOutside, useGetCalendarList } from "@runners/hooks"
import { DAY_LIST_KOR, getTodayFormat } from "@runners/lib/date"
import withModalStackProps from "@/_hoc/with-modal-stack-props"
import { cn } from "@/_styles/cn"
import { ChevronDownIcon } from "@/assets"
export interface CalendarModalProps {
  onClick: (date: string) => void
  todayButton?: boolean
  initialDate?: string
  yearType?: "past" | "middle" | "future" // 연도 범위가 어떻게 될지
}

/**
 * sprint17 에서 주디가 새롭게 작업한 새로운 캘린더입니다.
 * 이벤트 생성, 관계추가 등 여러 곳에서 이제 이걸 사용할겁니다. Calendar는 이제 없음.
 */
const CalendarModal = withModalStackProps<CalendarModalProps>(props => {
  const { onClick, initialDate, todayButton = false, yearType = "middle" } = props

  const today = getTodayFormat()
  const [selected, setSelected] = useState(initialDate || today)
  const [year, month] = selected.split("-")

  const calendarList = useGetCalendarList({ year, month }, {})

  // 한달 전으로
  const onClickPrev = () => {
    if (Number(month) === 1) {
      setSelected(`${Number(year) - 1}-12`)
    } else {
      setSelected(`${year}-${Number(month) - 1}`)
    }
  }

  const onClickNext = () => {
    if (Number(month) === 12) {
      setSelected(`${Number(year) + 1}-01`)
    } else {
      setSelected(`${year}-${Number(month) + 1}`)
    }
  }

  const onClickToday = () => {
    setSelected(today)
    onClick(today)
  }

  const [yearSelectOpen, setYearSelectOpen] = useState(false)

  const yearArray = useMemo(() => {
    if (!yearSelectOpen) return []

    if (yearType === "past") {
      return Array.from({ length: 100 }, (_, index) => Number(year) - 99 + index)
    }

    // 올해기준 -100 년 ~ + 50년
    return Array.from({ length: 150 }, (_, index) => Number(year) + 50 - index).reverse()
  }, [year, yearSelectOpen, yearType])

  const monthArray = useMemo(() => {
    if (!yearSelectOpen) return []
    return Array.from({ length: 12 }, (_, i) => i + 1)
  }, [yearSelectOpen])

  const yearRefs = useRef<HTMLButtonElement[]>([])
  const monthRefs = useRef<HTMLButtonElement[]>([])

  useEffect(() => {
    if (!yearSelectOpen) return
    yearRefs.current[Number(year)].scrollIntoView()
    monthRefs.current[Number(month)].scrollIntoView()
  }, [yearSelectOpen, year, month])

  const clickRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  useClickOutside({
    inRef: clickRef,
    exceptRef: buttonRef,
    handler: () => setYearSelectOpen(false),
  })

  return (
    <div className="w-[340px] rounded-2.5 border border-gray-300 bg-white px-4 py-7">
      <div className="mx-auto flex flex-col gap-6">
        <header className="relative flex items-center justify-between">
          <div className="flex items-center text-18 font-bold">
            {year}년 {Number(month)}월
            <button
              className="p-1"
              ref={buttonRef}
              onClick={() => {
                setYearSelectOpen(prev => !prev)
              }}
            >
              <ChevronDownIcon className="size-4" />
            </button>
            {yearSelectOpen && (
              <div ref={clickRef} className="absolute top-9 z-30 rounded-2 border border-gray-300 bg-white">
                <div className="flex">
                  <div className="scroll-none flex max-h-[168px] flex-col overflow-y-auto p-1 pr-0">
                    {yearArray.map(y => (
                      <button
                        key={y}
                        ref={el => {
                          if (!el) return
                          yearRefs.current[y] = el
                        }}
                        className={cn("min-w-[81px] flex-1 px-2.5 py-[7px] text-12 font-medium text-gray-700", {
                          "bg-gray-100": y === Number(year),
                        })}
                        onClick={() => {
                          setSelected(`${y}-${month}`)
                        }}
                      >
                        {y}년
                      </button>
                    ))}
                  </div>

                  <div className="scroll-none flex max-h-[168px] flex-col overflow-y-auto p-1 pl-0">
                    {monthArray.map(m => (
                      <button
                        key={m}
                        className={cn("min-w-[81px] flex-1 px-2.5 py-[7px] text-12 font-medium text-gray-700", {
                          "bg-gray-100": m === Number(month),
                        })}
                        ref={el => {
                          if (!el) return
                          monthRefs.current[m] = el
                        }}
                        onClick={() => {
                          setSelected(`${year}-${m}`)
                        }}
                      >
                        {m}월
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center gap-2">
            {todayButton && (
              <button
                onClick={onClickToday}
                className="rounded-1 border border-gray-300 px-2.5 py-0.5 text-14 text-gray-700"
              >
                오늘
              </button>
            )}

            <div className="flex rounded-1 border border-gray-300">
              <ChevronDownIcon className="size-4 rotate-90" onClick={onClickPrev} />

              <div className="h-6 w-px bg-gray-300" />
              <ChevronDownIcon className="size-4 -rotate-90" onClick={onClickNext} />
            </div>
          </div>
        </header>

        <main>
          <div className="grid grid-cols-[repeat(7,auto)] grid-rows-[auto] justify-center gap-0.5">
            {DAY_LIST_KOR.map(item => (
              <div key={item} className="flex w-10 cursor-default items-center justify-center p-2">
                <span
                  className={cn("p-0.5 text-center text-16 font-medium text-gray-700", {
                    "text-gray-400": item === "일" || item === "토",
                  })}
                >
                  {item}
                </span>
              </div>
            ))}
          </div>

          <div className="grid grid-cols-[repeat(7,auto)] grid-rows-[auto] justify-center gap-0.5 rounded-2 transition-all ease-in-out">
            {calendarList.map(item => {
              return (
                <div key={item.dateString} className="flex w-10 cursor-default items-center justify-center p-2">
                  <button
                    className="relative mt-2 flex flex-col items-center gap-1"
                    onClick={() => {
                      onClick(item.dateString)
                      setSelected(item.dateString)
                    }}
                  >
                    <div
                      className={cn("absolute size-7 rounded-full bg-primary text-white", {
                        block: selected === item.dateString,
                        hidden: selected !== item.dateString,
                      })}
                    />

                    <p
                      className={cn("relative z-10 p-0.5", {
                        "text-gray-400": (!item.isCurrentMonth && !item.isHoliDay) || item.isSaturday,
                        "text-red-200": item.isHoliDay && !item.isCurrentMonth,
                        "text-red-500": item.isHoliDay && item.isCurrentMonth,
                        "text-white font-medium": selected === item.dateString,
                      })}
                    >
                      {item.date}
                    </p>
                  </button>
                </div>
              )
            })}
          </div>
        </main>
      </div>
    </div>
  )
})

export default CalendarModal
