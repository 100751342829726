import AdminGuideCategory from "./fields/admin-guide-category"
import AdminGuideContents from "./fields/admin-guide-contents"
import AdminGuideTitle from "./fields/admin-guide-title"

const GuideFaqForm = ({ guideDetail }: { guideDetail?: { content: string } }) => {
  return (
    <div className="max-w-[1200px]">
      <AdminGuideTitle />
      <AdminGuideContents initialContent={guideDetail?.content} />
      <div className="mt-5" />
      <AdminGuideCategory />
    </div>
  )
}

export default GuideFaqForm
