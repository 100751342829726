import { useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useParams, useSearchParams } from "react-router-dom"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import Alert from "@/_components/modal/alert"
import useToast from "@/_hooks/use-toast"
import { useModalStack } from "@/_zustand/admin-modal-stack"
import GuideFaqForm from "../_components/admin-guide-faq-form"
import GuideTipForm from "../_components/admin-guide-tip-form"
import Loading from "../_components/loading"
import { useGetGuideQuery } from "../_hooks/use-get-query"
import { useDeleteGuideMutation, useStorePatchGuideMutation } from "../_hooks/use-mutation"

interface AdminGuideForm {
  thumbnail_image_url?: string | null
  mobile_thumbnail_image_url?: string | null
  title: string
  content: string
  sub_type: string
}

const AdminGuideDetailPage = () => {
  const { guideId: _guideId } = useParams<{ guideId: string }>()
  const guideId = Number(_guideId)
  const [searchParams] = useSearchParams()
  const type = searchParams.get("type") as "TIP" | "FAQ"

  const { data: guideDetail, isFetching } = useGetGuideQuery({ guideId })
  const methods = useForm<AdminGuideForm>()

  useEffect(() => {
    if (!guideDetail) return
    methods.reset({
      title: guideDetail.title,
      content: guideDetail.content || "<p></p>",
      thumbnail_image_url: guideDetail.thumbnail_image_url,
      mobile_thumbnail_image_url: guideDetail.mobile_thumbnail_image_url,
      sub_type: guideDetail.sub_type,
    })
  }, [guideDetail])

  const toast = useToast()
  const updateGuideMutation = useStorePatchGuideMutation({
    onSuccess: () => {
      toast.success("수정 완료!")
    },
    onError: () => {
      toast.error("수정 중 오류가 발생했습니다.")
    },
  })
  const deleteNoticeMutation = useDeleteGuideMutation({
    onSuccess: () => {
      toast.success("삭제 완료!")
      history.back()
    },
    onError: () => {
      toast.error("삭제 중 오류가 발생했습니다.")
    },
  })
  const modalStack = useModalStack()

  const handleDelete = () => {
    modalStack.open({
      key: "admin-guide-detail-delete",
      Component: Alert,
      componentProps: {
        title: "정말 삭제하시겠습니까?",
        onConfirm: () => {
          deleteNoticeMutation.mutate({ guideId })
          modalStack.pop()
        },
        onCancel: () => {
          modalStack.pop()
        },
        enabledEnterConfirm: true,
      },
    })
  }

  const onSubmit: SubmitHandler<AdminGuideForm> = data => {
    const payload = {
      ...data,
      type,
    }

    updateGuideMutation.mutate({ guideId, payload })
  }

  if (isFetching) return <Loading className="absolute inset-0 z-20 flex items-center justify-center size-screen" />

  return (
    <AdminPageLayout onDelete={handleDelete} onUpdate={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        {type === "TIP" ? <GuideTipForm guideDetail={guideDetail} /> : <GuideFaqForm guideDetail={guideDetail} />}
      </FormProvider>
    </AdminPageLayout>
  )
}

export default AdminGuideDetailPage
