
export const DAY_LIST = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"] as const
export const DAY_LIST_KOR = ["일", "월", "화", "수", "목", "금", "토"] as const

export const dateFormat = (date: string) => {
  // 1분, ..., 59분
  // 1시간, ..., 23시간
  // 1일, ..., 6일
  // 1주, ..., 3주
  const newDate = new Date(date)
  const now = new Date()
  const diff = now.getTime() - newDate.getTime()
  const minute = Math.abs(Math.floor(diff / 1000 / 60))
  const hour = Math.abs(Math.floor(diff / 1000 / 60 / 60))
  const day = Math.abs(Math.floor(diff / 1000 / 60 / 60 / 24))
  const week = Math.abs(Math.floor(diff / 1000 / 60 / 60 / 24 / 7))

  if (minute < 60) {
    return `${minute}분`
  }
  if (hour < 24) {
    return `${hour}시간`
  }
  if (day < 7) {
    return `${day}일`
  }
  return `${week}주`
}

export const strictDateFormat = (date?: string, type?: "dash" | "dot") => {
  if (!date) return ""
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = newDate.getMonth() + 1
  const day = newDate.getDate()

  if (type) {
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
  }

  return `${year}.${month < 10 ? `0${month}` : month}.${day < 10 ? `0${day}` : day}`
}

export const getDateString = (year: string | number, month: string | number, date: string | number) => {
  return `${year}-${Number(month) < 10 ? `0${month}` : month}-${Number(date) < 10 ? `0${date}` : date}`
}

export const getDDayString = (eventDate: string, expired = "") => {
  const today = new Date().getTime()
  const eventDay = new Date(eventDate).getTime()

  const gap = Math.ceil((eventDay - today) / (1000 * 60 * 60 * 24))

  switch (true) {
    case gap > 0:
      return `D-${gap}`
    case gap === 0:
      return "D-day"
    case gap <= 0:
      return expired
  }
}

export const getTodayFormat = () => {
  const today = new Date()
  return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(
    2,
    "0",
  )}`
}

export const dateFormatKo = (date?: string) => {
  if (!date) return ""
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = newDate.getMonth() + 1
  const day = newDate.getDate()

  return `${year}년 ${month < 10 ? `0${month}` : month}월 ${day < 10 ? `0${day}` : day}일`
}

export const dateFormatKoExceptYear = (date?: string) => {
  if (!date) return ""
  const newDate = new Date(date)
  const month = newDate.getMonth() + 1
  const day = newDate.getDate()

  return `${month < 10 ? `0${month}` : month}월 ${day < 10 ? `0${day}` : day}일`
}

/**
 * dateString 을 넣었을때 올해라면 MM월 DD일, 올해가 아니라면 YY년 MM월 DD일 로 표시해주는 함수
 */
export const formatDateString = (dateString: string) => {
  const inputDate = new Date(dateString)
  const currentDate = new Date()
  const inputYear = inputDate.getFullYear()
  const currentYear = currentDate.getFullYear()

  const month = inputDate.toLocaleString("default", { month: "long" }).replace(" ", "")
  const day = inputDate.getDate() // 날짜 (1-31)

  if (inputYear === currentYear) {
    return `${month} ${day}일`
  } else {
    return `${inputYear % 100}년 ${month} ${day}일`
  }
}

/**
 * dateString 을 넣었을때 요일을 반환하는 함수
 */

export const getDayOfWeek = (dateString: string) => {
  const days = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]
  const date = new Date(dateString)
  const dayIndex = date.getDay()

  return days[dayIndex]
}

/**
 * dateString 을 넣었을 때 현재 기준 24시간이 지나지 않았는지 반환하는 함수
 */

export const getIsNew = (dateString: string): boolean => {
  const date = new Date(dateString)
  const currentDate = new Date()

  const diffInMilliseconds = currentDate.getTime() - date.getTime()
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60)

  return diffInHours < 24
}
