import { cn } from "@runners/lib"
import { Image } from "@runners/ui"
import AdvertisementMark from "../components/advertisement-mark"

interface HomeObjectTypeBannerProps {
  label: string
  label_color: string

  title: string
  title_color: string
  description: string
  description_color: string

  thumbnail_image_url: string
  mobile_thumbnail_image_url: string

  is_advertisement: string

  link: string

  position?: string

  isMobile?: boolean
}

const HomeObjectTypeBanner = (props: HomeObjectTypeBannerProps) => {
  const {
    description,
    is_advertisement,
    label,
    label_color,
    link,
    thumbnail_image_url,
    mobile_thumbnail_image_url,
    title,
    position = "center",
    isMobile,
    description_color,
    title_color,
  } = props

  if (isMobile) {
    return (
      <a href={link}>
        <div className="relative h-[223px] w-[335px] overflow-hidden">
          <div className="flex size-full flex-col justify-end px-7 pb-6">
            <p style={{ color: title_color }} className="whitespace-pre-wrap text-20 font-bold leading-[26px]">
              {title}
            </p>
            <p style={{ color: description_color }} className="text-14 font-medium text-gray-600">
              {description}
            </p>
          </div>

          <div className="absolute top-0 -z-10 flex size-full">
            <Image size="2560" className="object-fill" src={mobile_thumbnail_image_url} />
          </div>
        </div>
      </a>
    )
  }

  return (
    <a href={link}>
      <div className="overflow-hidden bg-white px-5 md:h-[416px] md:w-[1920px] md:px-[200px]">
        <div className="flex h-full flex-col md:grid md:grid-cols-[3fr_2fr]">
          {/* 택스트 영역 */}
          <div className="h-[102px] w-full shrink-0 overflow-hidden md:w-[481px]">
            <p style={{ color: label_color }} className="text-12 font-bold text-primary md:text-24">
              {label}
            </p>
            <div className="mt-1.5 md:mt-5" />
            <p style={{ color: title_color }} className="whitespace-pre-wrap text-20 font-bold md:text-[48px]">
              {title}
            </p>
            <div className="mt-1.5 md:mt-[30px]" />
            <p
              style={{ color: description_color }}
              className="fixed whitespace-pre-wrap text-14 font-medium leading-[28px] md:text-[18px]"
            >
              {description}
            </p>
          </div>
          {/* 이미지 영역 */}
          <div
            className={cn("relative flex h-full w-[335px] justify-center md:w-[624px]", {
              "md:justify-start": position === "left",
              "md:justify-end": position === "right",
              "md:justify-center": position === "center",
            })}
          >
            {is_advertisement && <AdvertisementMark className="m-5" />}
            <div className="hidden items-center md:relative md:flex md:size-[370px]">
              <Image size="2560" className="object-none" src={thumbnail_image_url} />
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default HomeObjectTypeBanner
