import { useMutation } from "@tanstack/react-query"

import mediaApi from "@/_api/media"
import { MutationOptionsType } from "@/_types/react-query.type"

export const usePostVideoMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: mediaApi.postVideo,
    ...options,
  })
}
