import { useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import Alert from "@/_components/modal/alert"
import useToast from "@/_hooks/use-toast"
import { useModalStack } from "@/_zustand/admin-modal-stack"
import Loading from "../../guide/_components/loading"
import NoticeForm from "../_components/admin-notice-form"
import { useGetNoticeQuery } from "../_hooks/use-get-query"
import { useDeleteNoticeMutation, useStorePatchNoticeMutation } from "../_hooks/use-mutation"

interface AdminNoticeForm {
  start_date: string
  end_date: string
  title: string
  content: string
  type: "GENERAL" | "PROMOTION"
}

const AdminNoticeDetailPage = () => {
  const { noticeId: _noticeId } = useParams<{ noticeId: string }>()
  const noticeId = Number(_noticeId)

  const { data: noticeDetail, isFetching } = useGetNoticeQuery({ noticeId })
  const methods = useForm<AdminNoticeForm>()

  useEffect(() => {
    if (!noticeDetail) return

    const startDate = dayjs(noticeDetail.start_date).format("YYYY-MM-DD")
    const startTime = dayjs(noticeDetail.start_date).format("HH:mm")
    const endDate = dayjs(noticeDetail.end_date).format("YYYY-MM-DD")
    const endTime = dayjs(noticeDetail.end_date).format("HH:mm")

    methods.reset({
      type: noticeDetail.type,
      start_date: `${startDate} ${startTime}`,
      end_date: `${endDate} ${endTime}`,
      title: noticeDetail.title,
      content: noticeDetail.content,
    })
  }, [noticeDetail, methods])
  const toast = useToast()
  const updateNoticeMutation = useStorePatchNoticeMutation({
    onSuccess: () => {
      toast.success("수정 완료!")
    },
    onError: () => {
      toast.error("수정 중 오류가 발생했습니다.")
    },
  })
  const deleteNoticeMutation = useDeleteNoticeMutation({
    onSuccess: () => {
      toast.success("삭제 완료!")
      history.back()
    },
  })
  const modalStack = useModalStack()

  const handleDelete = () => {
    modalStack.open({
      key: "admin-notice-detail-delete",
      Component: Alert,
      componentProps: {
        title: "정말 삭제하시겠습니까?",
        onConfirm: () => {
          deleteNoticeMutation.mutate({ noticeId })
          modalStack.pop()
        },
        onCancel: () => {
          modalStack.pop()
        },
        enabledEnterConfirm: true,
      },
    })
  }

  const onSubmit: SubmitHandler<AdminNoticeForm> = data => {
    const payload = {
      ...data,
    }

    updateNoticeMutation.mutate({ noticeId, payload })
  }

  if (!noticeDetail) return <></>
  if (isFetching) return <Loading className="absolute inset-0 z-20 flex items-center justify-center size-screen" />

  return (
    <AdminPageLayout onDelete={handleDelete} onUpdate={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <NoticeForm noticeDetail={noticeDetail} />
        </form>
      </FormProvider>
    </AdminPageLayout>
  )
}

export default AdminNoticeDetailPage
