import React, { useState } from "react"
import dayjs from "dayjs"
import { AdminUserListDto, AdminUserListParams } from "@/_api/user.type"
import AdminTitle from "@/_components/admin-title"
import Image from "@/_components/image"
import FilterDropdown from "@/_components/table/filter-dropdown-table"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { useTableSearch } from "@/_hooks/use-table-search"
import { useGetUserList } from "./hooks/use-get-query"

/**
 * 회원관리
 */
const UserPage = () => {
  const { page, Table, pageSize } = usePaginationTable<AdminUserListDto>()
  const { SearchInput, search } = useTableSearch()

  const [staffFilterOption, setStaffFilterOption] = useState("")
  const [socialFilterOption, setSocialFilterOption] = useState("")
  const [marketingFilterOption, setMarketingFilterOption] = useState("")

  const staffFilterOptions = [
    { value: "", label: "유저/스태프" },
    { value: "true", label: "스태프만 보기" },
    { value: "false", label: "유저만 보기" },
  ]

  const socialFilterOptions = [
    { value: "", label: "가입 유형" },
    { value: "KAKAO", label: "카카오" },
    { value: "GOOGLE", label: "구글" },
    { value: "EMAIL", label: "이메일" },
  ]

  const marketingFilterOptions = [
    { value: "", label: "마케팅 동의 여부" },
    { value: "true", label: "동의" },
    { value: "false", label: "비동의" },
  ]

  // 다른생각2
  // const socialFilterMap: Record<string, undefined> = {
  //   "": undefined,
  //   KAKAO: "KAKAO",
  //   GOOGLE: "GOOGLE",
  //   EMAIL: "EMAIL",
  // }
  const params: AdminUserListParams = {
    page,
    page_size: pageSize,
    search,
    // 기존 staffFilterOption === "스태프만 보기" ? "true" : staffFilterOption === "유저만 보기" ? "false" : undefined
    is_staff: staffFilterOption,
    social_type: socialFilterOption,
    is_marketing_agreement: marketingFilterOption,
  }
  const { data: userList, totalCount, isLoading } = useGetUserList(params)

  return (
    <div className="">
      <AdminTitle count={totalCount} />
      <div className="h-10" />
      <div className="max-w-[calc(100vw-264px)] overflow-x-auto p-6">
        <div className="flex items-start gap-1">
          <div className="mt-1 flex h-[40px] items-center">
            <FilterDropdown
              options={staffFilterOptions}
              selectedOption={staffFilterOption}
              setSelectedOption={setStaffFilterOption}
            />
          </div>
          <div className="mt-1 flex h-[40px] items-center">
            <FilterDropdown
              options={socialFilterOptions}
              selectedOption={socialFilterOption}
              setSelectedOption={setSocialFilterOption}
            />
          </div>
          <div className="mt-1 flex h-[40px] items-center">
            <FilterDropdown
              options={marketingFilterOptions}
              selectedOption={marketingFilterOption}
              setSelectedOption={setMarketingFilterOption}
            />
          </div>
          <div className="flex flex-col">
            <SearchInput
              className="w-[500px]"
              description="가능한 필드: ID, 이름, 이메일, 휴대폰 번호"
              placeholder="검색어 입력"
            />
          </div>
        </div>
        <Table
          isLoading={isLoading}
          totalCount={totalCount}
          data={userList}
          className="min-w-[1600px]"
          title={[
            "ID",
            "이미지",
            "이름",
            "성별",
            "생년월일",
            "이메일",
            "휴대폰 번호",
            "생성한 이벤트",
            "가입일",
            "마지막 접속일",
            "마케팅 동의",
            "유저/스태프",
            "가입 유형",
            "상태",
          ]}
          widths={[2, 2, 2, 4, 2, 3, 6, 4, 4, 3, 4, 3, 2, 2, 2]}
          values={{
            ID: value => {
              return value?.id
            },
            이미지: value => {
              if (value?.profile_image_url == null) return "-"
              return (
                <div className="flex items-center justify-center">
                  <Image size="375" className="size-8 rounded-full" src={value?.profile_image_url} />
                </div>
              )
            },
            이름: value => {
              return value?.username
            },
            성별: value => {
              if (value?.gender == null) return "-"
              return value?.gender === "MALE" ? "남자" : "여자"
            },
            생년월일: value => {
              if (value?.birthday == null) return "-"
              return dayjs(value?.birthday).format("YYYY.MM.DD")
            },
            이메일: value => {
              return value?.email
            },
            "휴대폰 번호": value => {
              return value?.phone
            },
            "생성한 이벤트": value => {
              return value?.event_count
            },
            가입일: value => {
              return dayjs(value?.created).format("YY.MM.DD")
            },
            "마지막 접속일": value => {
              return dayjs(value?.last_login).format("YY.MM.DD")
            },
            "마케팅 동의": value => {
              return value?.is_marketing_agreement ? "동의" : "비동의"
            },
            "유저/스태프": value => {
              return value?.is_staff ? "스태프" : "유저"
            },
            "가입 유형": value => {
              return value?.social_type
            },
            상태: value => {
              return value?.is_signup ? "회원" : "탈퇴"
            },
          }}
        />

        <div className="h-10" />
      </div>
    </div>
  )
}

export default UserPage
