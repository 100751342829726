import { useFormContext } from "react-hook-form"
import { Textarea } from "@runners/ui"
import AdminLabel from "../admin-notice-label"

const AdminNoticeTitle = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-5">
        <AdminLabel required>제목</AdminLabel>
        <div>
          <Textarea className="w-[1080px]" {...register("title", { required: "제목을 입력해주세요." })} rows={1} />
        </div>
      </div>
      {errors.title && <p className="mt-1 text-sm text-red-500">{errors.title.message as string}</p>}
    </div>
  )
}

export default AdminNoticeTitle
