import React, { useState } from "react"
import { useKeydownFunction } from "@runners/hooks"
import { CloseIcon } from "@/assets"

interface StepModalProps {
  close: () => Promise<void>
  onCancel?: () => void
  onSubmit: (data: { stepNumber: string; title: string; content: string; image: string }) => void

  enabledEscClose?: boolean
  enabledEnterConfirm?: boolean
}

const StepModal = ({
  close,
  onSubmit,
  onCancel,
  enabledEscClose = false,
  enabledEnterConfirm = false,
}: StepModalProps) => {
  const [stepNumber, setStepNumber] = useState("")
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const [image, setImage] = useState("")

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => setImage(reader.result as string)
      reader.readAsDataURL(file)
    }
  }
  const handleClickCancel = () => {
    close?.()
    onCancel?.()
  }
  const handleSubmit = () => {
    onSubmit({ stepNumber, title, content, image })
    close()
  }
  useKeydownFunction({
    fn: handleSubmit,
    key: "Enter",
    enabled: enabledEnterConfirm,
  })
  useKeydownFunction({
    fn: close,
    key: "Escape",
    enabled: enabledEscClose,
  })
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="min-h-[80vh] w-[680px] overflow-y-auto rounded-lg bg-white shadow-lg">
        <div className="p-6">
          <div className="flex justify-between">
            <h2 className="text-20 mb-4 font-bold leading-[26px]">스텝 템플릿</h2>
            <CloseIcon onClick={close} className="size-6 cursor-pointer" />
          </div>
          <div className="my-1 h-px w-full bg-gray-300" />
          <div className="mb-4 ">
            <label className="text-14 mb-2">스텝 숫자</label>
            <input
              type="text"
              className="w-full rounded border p-2"
              value={stepNumber}
              onChange={e => setStepNumber(e.target.value)}
              placeholder="예: STEP 1"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-medium">제목</label>
            <input
              type="text"
              className="w-full rounded border p-2"
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder="제목을 입력해 주세요"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-medium">내용</label>
            <input
              className="w-full rounded border p-2"
              value={content}
              onChange={e => setContent(e.target.value)}
              placeholder="내용을 입력해 주세요"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-medium">이미지</label>
            <p className="mt-1 text-sm text-gray-500">가로 권장 길이: 1080px</p>
            <div className="flex min-h-[40vh] items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-4">
              {!image ? (
                <label className="cursor-pointer">
                  <span className="text-gray-500">+ Upload</span>
                  <input type="file" accept="image/*" className="hidden" onChange={handleImageUpload} />
                </label>
              ) : (
                <div className="flex size-full items-center justify-center">
                  <img src={image} alt="Preview" className="max-h-[40vh] w-full rounded-lg object-cover" />
                </div>
              )}
            </div>
          </div>
          <div className="my-1 mb-4 h-px w-full bg-gray-300" />

          <div className="flex justify-end gap-4">
            <button onClick={handleClickCancel} className="rounded bg-gray-300 px-4 py-2">
              닫기
            </button>
            <button onClick={handleSubmit} className="rounded bg-orange-500 px-4 py-2 text-white">
              추가
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepModal
