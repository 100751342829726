import { useQuery } from "@tanstack/react-query"
import guideQueryKey from "@/_api/_query-key/guide"
import guideApi from "@/_api/guide"
import { AdminGuideListParams } from "@/_api/guide.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetGuideListQuery = (params: AdminGuideListParams) => {
  return useInfiniteData({
    queryKey: guideQueryKey.guideList(params),
    queryFn: () => guideApi.getGuideList(params),
  })
}
export const useGetGuideQuery = ({ guideId }: { guideId: number }) => {
  return useQuery({
    queryKey: guideQueryKey.guideDetail({ guideId }),
    queryFn: () => guideApi.getGuide({ guideId }),
    select: response => response.data,
    refetchOnWindowFocus: false,
  })
}
