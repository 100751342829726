import { useId } from "react"

import Alert, { AlertProps } from "@/_components/modal/alert"
import { useModalStack } from "@/_zustand/admin-modal-stack"

/**
 * Alert
 */
export const useAlert = () => {
  const modalStack = useModalStack()
  const key = useId()

  const handleOpenAlert = (alertOptions: AlertProps) => {
    modalStack.open({
      key: `common-hooks-alert-${key}`,
      Component: Alert,
      componentProps: alertOptions,
    })
  }

  const close = () => {
    window.history.back()
  }

  const alert = { open: handleOpenAlert, close }
  return alert
}
