import React from "react"
import { cn } from "@runners/lib"

interface FilterDropdownProps {
  options: { value: string | number; label: string }[]
  selectedOption: string | number
  setSelectedOption: (value: string) => void
  className?: string
  description?: string
}

const FilterDropdown = ({
  options,
  selectedOption,
  setSelectedOption,
  className,
  description,
}: FilterDropdownProps) => {
  return (
    <div className="flex">
      {description && <div className="my-auto mr-2">{description}</div>}
      <select
        value={selectedOption}
        onChange={e => setSelectedOption(e.target.value)}
        className={cn("my-auto rounded border p-2", className)}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default FilterDropdown
