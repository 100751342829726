import { useMemo } from "react"
import { Badge } from "@runners/ui"
import { UploadStatus } from "./video-modal"

type ColorType = "gray" | "blue-green" | "red" | "primary" | "green" | "purple" | null | undefined

interface VideoModalUploadStatusProps {
  status: UploadStatus
}

const VideoModalUploadStatus = (props: VideoModalUploadStatusProps) => {
  const { status } = props

  const statusColor: ColorType = useMemo(() => {
    switch (status) {
      case "업로드 진행중":
        return "gray"
      case "업로드 완료":
        return "blue-green"
      case "업로드 실패":
        return "red"
    }
  }, [status])

  return (
    <Badge className="shrink-0" color={statusColor}>
      {status}
    </Badge>
  )
}

export default VideoModalUploadStatus
