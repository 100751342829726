import { Badge } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import noticeQueryKey from "@/_api/_query-key/notice"
import { AdminNoticeListDto } from "@/_api/notice.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { useGetNoticeListQuery } from "./_hooks/use-get-query"
import { useStoreActivateNoticeMutation, useStoreDeactivateNoticeMutation } from "./_hooks/use-mutation"

const NoticePage = () => {
  const { Table, page, pageSize } = usePaginationTable<AdminNoticeListDto>()
  const {
    data: noticeList,
    totalCount,
    isLoading,
  } = useGetNoticeListQuery({
    page_size: pageSize,
    page,
  })

  const qc = useQueryClient()

  const onSuccess = () => {
    const queryKey = noticeQueryKey.noticeList()
    qc.invalidateQueries({ queryKey })
  }
  const { mutate: activate } = useStoreActivateNoticeMutation({
    onSuccess,
  })
  const { mutate: deactivate } = useStoreDeactivateNoticeMutation({
    onSuccess,
  })

  const tableDropdownList: TableDropdownList = [
    {
      label: "선택항목 활성화",
      fn: ids => {
        activate({ notice_ids: ids })
      },
    },
    {
      label: "선택항목 비활성화",
      fn: ids => {
        deactivate({ notice_ids: ids })
      },
    },
  ]

  return (
    <AdminPageLayout totalCount={totalCount} create>
      <Table
        totalCount={totalCount}
        isLoading={isLoading}
        data={noticeList}
        tableDropdownList={tableDropdownList}
        href={value => {
          return `/store/notice/${value?.id}`
        }}
        className="min-w-[500px]"
        title={["ID", "상태", "구분", "이미지", "작성자", "제목", "조회수", "생성일", "마지막수정일", "노출기간"]}
        widths={[2, 2, 3, 4, 3, 6, 2, 2, 2, 5]}
        values={{
          ID: value => {
            return value?.id
          },
          상태: value => {
            return (
              <div className="flex items-center justify-center">
                {value?.is_active ? <Badge color="primary">활성화</Badge> : <Badge color="gray">비활성화</Badge>}
              </div>
            )
          },
          구분: value => value?.type,
          이미지: value => {
            return (
              <div className="flex items-center justify-center">
                <img className="h-10 w-32 rounded-1 object-cover" src={value.thumbnail_image_url as string} />
              </div>
            )
          },
          작성자: value => {
            return value.username
          },
          제목: value => {
            return value.title
          },
          조회수: value => {
            return value.visit_count
          },
          생성일: value => dayjs(value.created).format("YY.MM.DD"),
          마지막수정일: value => dayjs(value.modified).format("YY.MM.DD"),
          노출기간: value =>
            `${dayjs(value.start_date).format("YY.MM.DD")} ~ ${dayjs(value.end_date).format("YY.MM.DD")}`,
        }}
      />
    </AdminPageLayout>
  )
}

export default NoticePage
