import { AdminNoticeListParams } from "../notice.type"

const noticeQueryKey = {
  notice: () => ["notice"],

  noticeList: (params?: AdminNoticeListParams) => ["notice", "list", { ...params }],
  noticeDetail: ({ noticeId }: { noticeId: number }) => ["notice", "detail", noticeId],
}

export default noticeQueryKey
