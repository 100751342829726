import React, { useState } from "react"
import { Ordering } from "@runners/interface"
import { cn } from "@runners/lib"
import { Badge } from "@runners/ui"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import guideQueryKey from "@/_api/_query-key/guide"
import { AdminGuideListDto } from "@/_api/guide.type"
import AdminPageLayout from "@/_components/layout/admin-page-layout"
import { usePaginationTable } from "@/_components/table/pagination-table"
import { TableDropdownList } from "@/_components/table/table-dropdown"
import { useGetGuideListQuery } from "./_hooks/use-get-query"
import { useStoreActivateGuideMutation, useStoreDeactivateGuideMutation } from "./_hooks/use-mutation"

const GuidePage = () => {
  const [activeTab, setActiveTab] = useState<"판매 활용 Tip" | "FAQ">("판매 활용 Tip")

  const { Table, page, pageSize } = usePaginationTable<AdminGuideListDto>()
  const {
    data: guideList,
    totalCount,
    isLoading,
    error,
  } = useGetGuideListQuery({
    page_size: pageSize,
    page,
    type: activeTab === "판매 활용 Tip" ? "TIP" : "FAQ",
    ordering: Ordering.CREATED_ASC,
  })

  const qc = useQueryClient()

  const onSuccess = () => {
    const queryKey = guideQueryKey.guideList()
    qc.invalidateQueries({ queryKey })
  }
  const { mutate: activate } = useStoreActivateGuideMutation({ onSuccess })
  const { mutate: deactivate } = useStoreDeactivateGuideMutation({ onSuccess })

  const tableDropdownList: TableDropdownList = [
    {
      label: "선택항목 활성화",
      fn: ids => activate({ guide_ids: ids }),
    },
    {
      label: "선택항목 비활성화",
      fn: ids => deactivate({ guide_ids: ids }),
    },
  ]

  const values = {
    ID: (data: AdminGuideListDto) => data.id,
    상태: (data: AdminGuideListDto) => (
      <div className="flex items-center justify-center">
        {data.is_active ? <Badge color="primary">활성화</Badge> : <Badge color="gray">비활성화</Badge>}
      </div>
    ),
    이미지: (data: AdminGuideListDto) => (
      <div className="flex items-center justify-center">
        <img className="h-10 w-32 rounded-1 object-cover" src={data?.thumbnail_image_url as string} />
      </div>
    ),
    작성자: (data: AdminGuideListDto) => data.username,
    제목: (data: AdminGuideListDto) => data.title,
    조회수: (data: AdminGuideListDto) => data.visit_count,
    생성일: (data: AdminGuideListDto) => dayjs(data.created).format("YY.MM.DD"),
    마지막수정일: (data: AdminGuideListDto) => dayjs(data.modified).format("YY.MM.DD"),
  }

  return (
    <AdminPageLayout
      totalCount={totalCount}
      createPath={`/store/guide/create?type=${activeTab === "판매 활용 Tip" ? "TIP" : "FAQ"}`}
    >
      <div className="flex border-b">
        {["판매 활용 Tip", "FAQ"].map(tab => (
          <button
            key={tab}
            className={cn("px-4 py-2", {
              "text-orange-500 font-bold": activeTab === tab,
              "border-transparent": activeTab !== tab,
            })}
            onClick={() => setActiveTab(tab as "판매 활용 Tip" | "FAQ")}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="p-4">
        {isLoading ? (
          <p>데이터를 불러오는 중입니다...</p>
        ) : error ? (
          <p>데이터를 불러오는 데 실패했습니다.</p>
        ) : (
          <Table
            totalCount={totalCount}
            isLoading={isLoading}
            data={guideList}
            tableDropdownList={tableDropdownList}
            href={value => `/store/guide/${value?.id}?type=${activeTab === "판매 활용 Tip" ? "TIP" : "FAQ"}`}
            className="min-w-[500px]"
            title={["ID", "상태", "이미지", "작성자", "제목", "조회수", "생성일", "마지막수정일"]}
            widths={[2, 3, 5, 3, 5, 2, 3, 3]}
            values={values}
          />
        )}
      </div>
    </AdminPageLayout>
  )
}

export default GuidePage
