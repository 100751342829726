import { Button } from "@runners/ui"
import AdminNoticeCategory from "./fields/admin-notice-category"
import AdminNoticeContent from "./fields/admin-notice-content"
import AdminNoticeEndDate from "./fields/admin-notice-end-date"
import AdminNoticeStartDate from "./fields/admin-notice-start-date"
import AdminNoticeTitle from "./fields/admin-notice-title"

interface NoticeFormProps {
  noticeDetail?: { content: string }
}
const NoticeForm = ({ noticeDetail = { content: "" } }: NoticeFormProps) => {
  return (
    <div className="max-w-[1200px]">
      <AdminNoticeStartDate />
      <div className="mt-5" />
      <AdminNoticeEndDate />
      <div className="mt-5" />

      <AdminNoticeTitle />

      <AdminNoticeContent initialContent={noticeDetail?.content} />
      <div className="mt-5" />

      <AdminNoticeCategory />

      <div className="my-6 h-px w-[1340px] bg-gray-300" />
      <Button type="submit" size="48" className="w-[1340px]">
        등록
      </Button>
    </div>
  )
}

export default NoticeForm
