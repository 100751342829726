import { AdminGuideListParams } from "../guide.type"

const guideQueryKey = {
  guide: () => ["guide"],

  guideList: (params?: AdminGuideListParams) => ["guide", "list", { ...params }],
  guideDetail: ({ guideId }: { guideId: number }) => ["guide", "detail", guideId],
}

export default guideQueryKey
