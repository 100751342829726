import { DjangoListResponse, DjangoResponse } from "@runners/interface"
import api, { METHOD } from "."
import {
  AdminGuideDto,
  AdminGuideListDto,
  AdminGuideListParams,
  PostAdminGuidePayload,
  PatchAdminGuidePayload,
} from "./guide.type"

const guideApi = {
  /**
   * 포스트 삭제
   */
  deletePost: async ({ guideId }: { guideId: number }) => {
    const { data } = await api(`/admin/guide/${guideId}`, {
      method: METHOD.DELETE,
    })
    return data
  },
  /**
   * 게시글 리스트 조회
   */
  getGuideList: async (params: AdminGuideListParams) => {
    const { data } = await api<DjangoListResponse<AdminGuideListDto>>(`/admin/guides`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  /**
   * 게시글 객체 조회
   */
  getGuide: async ({ guideId }: { guideId: number }) => {
    const { data } = await api<DjangoResponse<AdminGuideDto>>(`/admin/guide/${guideId}`, {
      method: METHOD.GET,
    })
    return data
  },

  /**
   * 게시글 생성
   */
  postGuide: async ({ payload }: { payload: PostAdminGuidePayload }) => {
    const { data } = await api(`/admin/guide`, {
      method: METHOD.POST,
      data: payload,
    })
    return data
  },

  /**
   * 게시글 수정
   */
  patchGuide: async ({ guideId, payload }: { guideId: number; payload: PatchAdminGuidePayload }) => {
    const { data } = await api(`/admin/guide/${guideId}`, {
      method: METHOD.PATCH,
      data: payload,
    })
    return data
  },

  /**
   * 게시글 선택 활성화
   */
  postGuideActivate: async ({ guide_ids }: { guide_ids: number[] }) => {
    const { data } = await api(`/admin/guide/activate`, {
      method: METHOD.POST,
      data: { guide_ids },
    })
    return data
  },

  /**
   * 게시글 선택 비활성화
   */
  postGuideDeactivate: async ({ guide_ids }: { guide_ids: number[] }) => {
    const { data } = await api(`/admin/guide/deactivate`, {
      method: METHOD.POST,
      data: { guide_ids },
    })
    return data
  },
}

export default guideApi
