import { useQuery } from "@tanstack/react-query"
import noticeQueryKey from "@/_api/_query-key/notice"
import noticeApi from "@/_api/notice"
import { AdminNoticeListParams } from "@/_api/notice.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetNoticeListQuery = (params: AdminNoticeListParams) => {
  return useInfiniteData({
    queryKey: noticeQueryKey.noticeList(params),
    queryFn: () => noticeApi.getNoticeList(params),
  })
}
export const useGetNoticeQuery = ({ noticeId }: { noticeId: number }) => {
  return useQuery({
    queryKey: noticeQueryKey.noticeDetail({ noticeId }),
    queryFn: () => noticeApi.getNotice({ noticeId }),
    select: response => response.data,
  })
}
