import { useFormContext } from "react-hook-form"
import AdminLabel from "../admin-notice-label"

const AdminNoticeCategory = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-5">
        <AdminLabel required>카테고리</AdminLabel>
        <div className="w-full">
          <select
            {...register("type", { required: "카테고리를 선택해주세요." })}
            defaultValue=""
            className="w-[200px] rounded border p-3 shadow-sm focus:outline-none"
          >
            <option value="" disabled>
              선택해 주세요
            </option>
            <option value="GENERAL">공지사항</option>
            <option value="PROMOTION">프로모션</option>
          </select>
        </div>
      </div>
      {errors.type && <p className="mt-1 text-sm text-red-500">{errors.type.message as string}</p>}
    </div>
  )
}

export default AdminNoticeCategory
