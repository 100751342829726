import { useMutation } from "@tanstack/react-query"
import noticeApi from "@/_api/notice"
import { MutationOptionsType } from "@/_types/react-query.type"

export const useStoreActivateNoticeMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: noticeApi.postNoticeActivate,
    ...options,
  })
}

export const useStoreDeactivateNoticeMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: noticeApi.postNoticeDeactivate,
    ...options,
  })
}

export const useStorePatchNoticeMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: noticeApi.patchNotice,
    ...options,
  })
}

export const useStorePostNoticeMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: noticeApi.postNotice,
    ...options,
  })
}

export const useDeleteNoticeMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: noticeApi.deletePost,
    ...options,
  })
}
