import { ChangeEvent, useState } from "react"
import { Button, LabelButton } from "@runners/ui"

import withModalLayout from "@/_hoc/with-modal-layout"
import { useAlert } from "@/_hooks/use-alert"
import { usePostVideoMutation } from "@/_hooks/use-media-mutation"
import { PlusIcon } from "@/assets"
import VideoModalListItem from "./video-modal-list-item"

type VideoUploadStep = "1" | "2"

export type UploadStatus = "업로드 진행중" | "업로드 완료" | "업로드 실패"

const ONE_HUNDRED_MB = 100 * 1024 * 1024

export interface VideoUploadItem {
  name: string
  url: string
  isError: boolean
  isLoading: boolean
  thumbnail: string
  status: UploadStatus
}

interface VideoModalProps {
  onSubmit: (data: string[]) => void
  onCancel: () => void
}

const VideoModal = withModalLayout<VideoModalProps>(props => {
  const { close, onSubmit, onCancel } = props

  const [step, setStep] = useState<VideoUploadStep>("1")

  const alert = useAlert()

  const { mutateAsync } = usePostVideoMutation({})

  const [uploadList, setUploadList] = useState<VideoUploadItem[]>([]) // 업로드 상태를 관리하는 상태

  /** ------------------------------------------------------------------------------
   * 
   * 예외처리를 프론트에서 해야해서 함수자체가 좀 뚱뚱합니다
   * 한줄한줄 읽어가다보면 그렇게 어려운 함수는 아님 끌끌
   * 
   ------------------------------------------------------------------------------ */
  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const files = Array.from(e.target.files)

    if (files.length > 10) {
      alert.open({
        title: "파일은 최대 10개까지 첨부 가능해요",
        content: "동영상 파일 업로드는 최대 10개입니다.\n10개 이하의 동영상을 선택해 주세요.",
        submitOnly: true,
      })
      e.target.value = ""
      return
    }

    /**
     * 용량 체크
     */
    const exceededSize = []
    for (const file of files) {
      if (file.size > ONE_HUNDRED_MB) {
        exceededSize.push(file.name)
      }
    }
    if (exceededSize.length > 0) {
      alert.open({
        title: `${exceededSize.length}개의 파일이\n용량을 넘었어요`,
        content: (
          <div className="flex w-full flex-col gap-2 bg-gray-100 px-4 py-3">
            {exceededSize.map(v => (
              <div key={v} className="flex w-full items-center gap-2">
                <p className="line-clamp-1 w-full text-ellipsis text-14 text-gray-700">{v}</p>
                <p className="shrink-0 text-12 text-primary">용량초과</p>
              </div>
            ))}
          </div>
        ),
        submitOnly: true,
      })
      e.target.value = ""
      return
    }

    if (step === "1") setStep("2")

    /**
     * 영상 업로드
     */
    const initialUploadList = files.map(file => ({
      url: "",
      thumbnail: "",
      name: file.name,
      isLoading: true,
      isError: false,
      status: "업로드 진행중" as const,
    }))

    const prevListLength = uploadList.length
    const totalList = uploadList.concat(initialUploadList)

    setUploadList(totalList)

    for (let i = prevListLength; i < totalList.length; i++) {
      const file = files[i - prevListLength]
      const formData = new FormData()
      formData.append("file", file)

      try {
        const { data } = await mutateAsync({ formData })
        const thumbnail: string = await _generateThumbnail(data.url)

        setUploadList(prev => {
          return prev.map((v, index) => {
            if (index === i) {
              return {
                ...v,
                status: "업로드 완료",
                thumbnail,
                isError: false,
                isLoading: false,
                url: data.url,
              }
            }

            return v
          })
        })
      } catch (e) {
        setUploadList(prev => {
          return prev.map((v, index) => {
            if (index === i) {
              return {
                ...v,
                thumbnail: "",
                isError: true,
                isLoading: false,
                status: "업로드 실패",
              }
            }
            return v
          })
        })
      }
    }
  }

  /**
   * 배열 지우기
   */
  const handleRemove = (index: number) => {
    setUploadList(prev => {
      return prev.slice(0, index).concat(prev.slice(index + 1))
    })
  }

  /**
   * submit 후 Promise 반환값을 성공한 비디오들의 url만 string[] 형식으로 에디터에 전송
   */
  const handleSubmit = () => {
    const successArray = uploadList.filter(v => !v.isError && !v.isLoading).map(v => v.url)
    onSubmit(successArray)
  }

  const handleClose = () => {
    onCancel()
    close()
  }

  return (
    <div>
      {step === "1" && (
        <div className="flex flex-col items-center justify-center bg-gray-100 p-5 py-[126px]">
          <p className="text-16 text-gray-600">동영상 파일은 최대 10개,</p>
          <p className="text-16 text-gray-600">파일의 용량은 100MB / 15분까지 업로드 가능합니다.</p>

          <input
            name="video-input"
            onChange={handleUpload}
            id="video-input"
            type="file"
            className="hidden"
            multiple
            accept="video/*"
          />

          <LabelButton htmlFor="video-input" size="40" color="primary" className="mt-4 px-5">
            동영상 추가
          </LabelButton>
        </div>
      )}

      {step === "2" && (
        <div className="flex max-h-[358px] flex-col gap-2 overflow-y-auto p-5">
          {uploadList.map((item, index) => (
            <VideoModalListItem
              key={`${item.name}_${index}`}
              item={item}
              index={index}
              onRemove={() => handleRemove(index)}
            />
          ))}
        </div>
      )}

      <footer className="flex justify-between gap-2 border-t border-t-gray-300 p-5">
        {step === "2" && (
          <>
            <input
              name="video-input"
              onChange={handleUpload}
              id="video-input"
              type="file"
              className="hidden"
              multiple
              accept="video/*"
            />

            <LabelButton
              htmlFor="video-input"
              size="40"
              color="white"
              className="group flex items-center justify-center gap-1"
            >
              <PlusIcon className="size-4 text-primary group-hover:text-primary-500" />
              <span className="text-16 font-normal text-primary group-hover:text-primary-500">동영상 추가</span>
            </LabelButton>
          </>
        )}
        <div />

        <div className="flex gap-2">
          <Button size="40" color="gray" onClick={handleClose} className="rounded-1 px-5 font-normal text-gray-800">
            닫기
          </Button>

          <Button
            size="40"
            disabled={uploadList.filter(v => !v.isError && !v.isLoading).length === 0}
            onClick={handleSubmit}
            className="px-5"
          >
            완료
          </Button>
        </div>
      </footer>
    </div>
  )
})

export default VideoModal

function _generateThumbnail(videoUrl: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const video = document.createElement("video")
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")

    video.src = videoUrl
    video.crossOrigin = "anonymous" // CORS 이슈가 있는 경우

    if (!context) {
      reject(new Error("Failed to get 2D context from canvas"))
      return
    }

    video.addEventListener("loadedmetadata", () => {
      // 비디오의 특정 시간으로 이동 (예: 1초)
      video.currentTime = 1
    })

    video.addEventListener("seeked", () => {
      // 비디오의 현재 프레임을 캔버스에 그리기
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      context.drawImage(video, 0, 0, canvas.width, canvas.height)

      // 썸네일 이미지 URL로 변환
      const thumbnailUrl = canvas.toDataURL("image/jpeg")
      resolve(thumbnailUrl)
    })

    // 에러가 발생한 경우
    video.addEventListener("error", err => {
      console.error("Error generating thumbnail:", err)
      reject("")
    })

    video.load()
  })
}
