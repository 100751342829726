import { PropsWithChildren } from "react"
import AdminTitle from "../admin-title"

interface ListPageType {
  totalCount?: number
  onDelete?: undefined
  onUpdate?: undefined
  create?: boolean
  createPath?: string
}

interface DetailPageType {
  totalCount?: undefined
  onDelete?: () => void
  onUpdate?: () => void
  create?: undefined
  createPath?: undefined
}

type AdminPageLayoutProps = (ListPageType & PropsWithChildren<{}>) | (DetailPageType & PropsWithChildren<{}>)

/**
 * 페이지별 기본 레이아웃입니다.
 *
 * 리스트페이지에서는 totalCount만을 넣어야합니다.
 * 상세페이지에서는 onDelete만을 넣어야합니다.
 *
 */
const AdminPageLayout = (props: AdminPageLayoutProps) => {
  const { children, onDelete, totalCount, create, createPath, onUpdate } = props

  return (
    <div className="">
      <AdminTitle
        count={totalCount || 0}
        onDelete={onDelete}
        create={create}
        createPath={createPath}
        onUpdate={onUpdate}
      />
      <div className="h-10" />
      <div className="min-h-[80vh] max-w-[calc(100vw-264px)] overflow-x-auto p-6">{children}</div>
      <div className="h-10" />
    </div>
  )
}

export default AdminPageLayout
