import { useMutation } from "@tanstack/react-query"
import guideApi from "@/_api/guide"
import { MutationOptionsType } from "@/_types/react-query.type"

export const useStoreActivateGuideMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: guideApi.postGuideActivate,
    ...options,
  })
}

export const useStoreDeactivateGuideMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: guideApi.postGuideDeactivate,
    ...options,
  })
}

export const useStorePatchGuideMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: guideApi.patchGuide,
    ...options,
  })
}

export const useStorePostGuideMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: guideApi.postGuide,
    ...options,
  })
}

export const useDeleteGuideMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: guideApi.deletePost,
    ...options,
  })
}
