"use client"

import { useMemo } from "react"
import { getDateString, getTodayFormat } from "@runners/lib/date"

export interface CalendarListType {
  date: number
  dateString: string
  isCurrentMonth: boolean
  isToday: boolean
  isHoliDay: boolean
  isSaturday: boolean
}

export const useGetCalendarList = (dates: { year: string | number; month: string | number }, _addFunction?: any) => {
  const { year: _year, month: _month } = dates

  const year = Number(_year)
  const month = Number(_month)

  const dateList = useMemo(() => {
    const today = getTodayFormat()

    const firstDay = new Date(year, month - 1).getDay()
    const lastDate = new Date(year, month, 0).getDate()
    let prevLastDate = new Date(year, month - 1, 0).getDate()

    const prevDateList = Array.from({ length: firstDay }, () => {
      const date = new Date(month === 0 ? year - 1 : year, month === 0 ? 11 : month - 2, prevLastDate)
      const dateString = getDateString(month === 0 ? year - 1 : year, month === 0 ? "12" : month - 1, prevLastDate)

      return {
        date: prevLastDate--,
        dateString,
        isCurrentMonth: false,
        isToday: false,
        isHoliDay: date.getDay() === 0,
        isSaturday: date.getDay() === 6,
      }
    }).reverse()

    const currentDateList = Array.from({ length: lastDate }, (_, i) => {
      const date = new Date(year, month - 1, i + 1)

      const dateString = getDateString(year, month, i + 1)

      return {
        date: i + 1,
        dateString,
        isCurrentMonth: true,
        isToday: today === dateString,
        isHoliDay: date.getDay() === 0,
        isSaturday: date.getDay() === 6,
      }
    })

    const nextDateList = Array.from({ length: 7 - ((firstDay + lastDate) % 7 || 7) }, (_, i) => {
      const date = new Date(month + 1 === 13 ? year + 1 : year, month === 12 ? 0 : month, i + 1)
      const dateString = getDateString(month + 2 === 13 ? year + 1 : year, month + 1 === 13 ? "1" : month + 1, i + 1)

      return {
        date: i + 1,
        dateString,
        isCurrentMonth: false,
        isToday: false,
        isHoliDay: date.getDay() === 0,
        isSaturday: date.getDay() === 6,
      }
    })

    return [...prevDateList, ...currentDateList, ...nextDateList]
  }, [year, month])

  return dateList
}
