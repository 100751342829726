import { useFormContext, useWatch } from "react-hook-form"
import Spinner from "@/_components/spinner"
import { useUploadImage } from "@/_hooks/use-upload"

import { CloseIcon } from "@/assets"
import AdminLabel from "../admin-guide-label"

interface AdminGuideMobileThumbnailProps {}

const AdminGuideMobileThumbnail = (props: AdminGuideMobileThumbnailProps) => {
  const {} = props

  const { setValue } = useFormContext()

  const watch = useWatch()

  const { onChange, isPending } = useUploadImage({
    onSuccess: response => {
      setValue("mobile_thumbnail_image_url", response.data.url)
    },
  })

  const removeImage = () => {
    if (confirm("정말 지울거에오?")) {
      setValue("mobile_thumbnail_image_url", null)
    }
  }

  return (
    <div className="mb-7 flex gap-5">
      <div>
        <AdminLabel>배너(모바일)</AdminLabel>
        <span className="text-14 text-[#767685]">335*122의 사이즈 이미지를 넣어주세요.</span>
      </div>

      <div className="relative mt-2 h-[122px] w-[335px] rounded-2">
        {watch?.mobile_thumbnail_image_url && (
          <>
            <button
              type="button"
              onClick={removeImage}
              className="group absolute -right-2 -top-2 flex size-6 items-center justify-center rounded-full bg-white hover:scale-110"
            >
              <CloseIcon className="size-4 text-gray-600 group-hover:text-gray-900" />
            </button>
            <img className="h-[122px] w-[335px] rounded-2 object-cover" src={watch?.mobile_thumbnail_image_url} />
          </>
        )}

        {!watch?.mobile_thumbnail_image_url && (
          <label className="relative mt-2 block h-[122px] w-full cursor-pointer rounded-2 border border-gray-300 hover:border-primary-700">
            {isPending && (
              <div className="absolute inset-0">
                <Spinner />
              </div>
            )}
            <input
              name="mobile_thumbnail_image_url"
              onChange={onChange}
              className="hidden"
              type="file"
              accept="image/*"
            />
          </label>
        )}
      </div>
    </div>
  )
}

export default AdminGuideMobileThumbnail
