import { useFormContext } from "react-hook-form"
import { Textarea } from "@runners/ui"
import AdminFormField from "@/_components/admin-form-field"
import ColorPickerLabelButton from "../../color-picker-label-button"

const AdminHomeDefaultFields = () => {
  const { register } = useFormContext()
  return (
    <>
      <AdminFormField label="타이틀">
        <Textarea {...register("title")} className="min-w-[400px]" />
        <ColorPickerLabelButton name="title_color" label="타이틀 색상 선택" />
      </AdminFormField>
      <AdminFormField label="내용">
        <Textarea {...register("description")} className="min-w-[400px]" />
        <ColorPickerLabelButton name="description_color" label="타이틀 색상 선택" />
      </AdminFormField>
    </>
  )
}

export default AdminHomeDefaultFields
