import { useMemo } from "react"
import { statusOptionList } from "@runners/lib/constants"
import { Badge, BadgeProps } from "@runners/ui"

interface StoreStatusBadgeProps {
  status: (typeof statusOptionList)[number]["value"]
  is_active: boolean
}

const StoreStatusBadge = (props: StoreStatusBadgeProps) => {
  const { status, is_active } = props

  const currentState = statusOptionList.find(option => option.value === status)

  const label = useMemo(() => {
    if (!is_active) return "비활성화"
    return currentState?.label
  }, [is_active, currentState])
  const badgeColor: BadgeProps["color"] = useMemo(() => {
    if (!is_active) return "gray"
    switch (status) {
      case "PENDING":
        return "gray"
      case "HOLD":
        return "green"
      case "ON_SALE":
        return "primary"
      case "SUSPEND":
        return "red"
      case "OUT_OF_STOCK":
        return "purple"
      default:
        return "gray"
    }
  }, [status, is_active])

  return <Badge color={badgeColor}>{label}</Badge>
}

export default StoreStatusBadge
