import { useFormContext } from "react-hook-form"
import { Button } from "@runners/ui"
import AdminHomeDefaultFields from "./admin-home-default-fields"
import usePreviewModal from "../../../_hooks/use-preview-modal"
import HomeObjectTypeBanner from "../../preview/home/home-object-type-banner"
import ShouldUnregisterThumbnailInput from "../../should-unregister-thumbnail-input"

const AdminHomeObject = () => {
  const { register } = useFormContext()

  const { openModal } = usePreviewModal({
    Component: HomeObjectTypeBanner,
    key: "admin-home-object-preview",
  })

  return (
    <>
      <AdminHomeDefaultFields />

      {/* NOTE: 베너 component만들 때 유의점 이미지크기를 그대로 유지해야하며 position에 따라 위치 조정 필요 */}
      <ShouldUnregisterThumbnailInput name="thumbnail_image_url" label="웹 이미지" key="web-image" className="w-full" />
      <ShouldUnregisterThumbnailInput
        name="mobile_thumbnail_image_url"
        label="모바일 이미지"
        key="mobile-image"
        className="w-[375px]"
      />

      {/* Preview */}
      <div>
        <Button size="48" onClick={openModal}>
          미리보기
        </Button>
      </div>
    </>
  )
}

export default AdminHomeObject
