import { useFormContext } from "react-hook-form"
import { AdminGuideEditor } from "@runners/editor"
import "@runners/editor/dist/style.css"
import { LinkPreviewDto } from "@runners/interface"
import AddLinkModal from "@/_pages/service/post/_components/modal/add-link-modal"
import { useModalStack } from "@/_zustand/admin-modal-stack"
import VideoModal from "../video/video-modal"

const AdminGuideContents = ({
  initialContent,
  onStepTemplate,
  onChangeEditor,
}: {
  initialContent?: string
  onStepTemplate?: () => Promise<{ stepNumber: string; title: string; content: string; image: string }>
  onChangeEditor?: (content: string) => void
}) => {
  const modalStack = useModalStack()
  const { setValue } = useFormContext()

  const onLinkPreview = () => {
    return new Promise<LinkPreviewDto>(resolve => {
      modalStack.open({
        Component: AddLinkModal,
        componentProps: {
          onSubmit: (data: LinkPreviewDto) => {
            resolve(data)
          },
        },
        key: "링크프리뷰모달",
        disableBackdropClick: true,
      })
    })
  }
  const onUploadVideo = () => {
    return new Promise<string[]>((resolve, reject) => {
      modalStack.open({
        Component: VideoModal,
        componentProps: {
          title: "동영상",
          layoutClassName: "w-full w-[680px]",
          onSubmit: (data: string[]) => {
            resolve(data)
            modalStack.pop()
          },
          onCancel: () => {
            reject()
          },
        },
        key: "동영상프리뷰모달",
        disableBackdropClick: true,
      })
    })
  }
  const onChange = (editorContent: string) => {
    onChangeEditor?.(editorContent)
    setValue("content", editorContent)
  }

  return (
    <>
      <div>
        <AdminGuideEditor
          onLinkPreview={onLinkPreview}
          onStepTemplate={onStepTemplate}
          onUploadVideo={onUploadVideo}
          onChangeEditor={onChange}
          initialValues={initialContent}
        />
      </div>
    </>
  )
}

export default AdminGuideContents
