/**
 * 레이아웃 쉬프트를 방지용 BLANK
 */
export const CHAR_BLANK = "ㅤ";

/** ------------------------------------------------------------------------------
 * 
 * for store
 * 
 ------------------------------------------------------------------------------ */
export const statusOptionList = [
  { value: "PENDING", label: "승인대기" },
  { value: "HOLD", label: "승인보류" },
  { value: "ON_SALE", label: "판매중" },
  { value: "SUSPEND", label: "판매중지" },
  { value: "OUT_OF_STOCK", label: "일시품절" },
] as const;

export enum OrderStatus {
  /** 결제 전 상태입니다. */
  // PENDING = "PENDING",
  /** 결제완료 */
  PAYMENT_COMPLETE = "PAYMENT_COMPLETE",
  /** 상품준비중 */
  PRODUCT_PREPARING = "PRODUCT_PREPARING",
  /** 배송중 */
  SHIPPING_ONGOING = "SHIPPING_ONGOING",
  /** 배송완료 */
  SHIPPING_COMPLETE = "SHIPPING_COMPLETE",
  /** 교환/환불 요청 */
  RETURN_EXCHANGE_REQUEST = "RETURN_EXCHANGE_REQUEST",
  /** 교환/환불 중 */
  RETURN_EXCHANGE_ONGOING = "RETURN_EXCHANGE_ONGOING",
  /** 교환/환불 완료 */
  RETURN_EXCHANGE_COMPLETE = "RETURN_EXCHANGE_COMPLETE",
  /** 주문취소 완료 */
  CANCEL_COMPLETE = "CANCEL_COMPLETE",
}

/**
 * 프론트에서 쓰기쉽게
 */
export const OrderStatusLabelMap = {
  // [OrderStatus.PENDING]: "입금대기",
  [OrderStatus.PAYMENT_COMPLETE]: "결제완료",
  [OrderStatus.PRODUCT_PREPARING]: "상품준비중",
  [OrderStatus.SHIPPING_ONGOING]: "배송중",
  [OrderStatus.SHIPPING_COMPLETE]: "배송완료",
  [OrderStatus.RETURN_EXCHANGE_REQUEST]: "교환/반품요청",
  [OrderStatus.RETURN_EXCHANGE_ONGOING]: "교환/반품중",
  [OrderStatus.RETURN_EXCHANGE_COMPLETE]: "교환/반품완료",
  [OrderStatus.CANCEL_COMPLETE]: "주문취소완료",
};

export const orderStatusList = Object.entries(OrderStatusLabelMap).map(
  ([value, label]) => ({
    label,
    value: value as OrderStatus,
  })
);

/** ------------------------------------------------------------------------------
 * 
 * 배송 상태
 * 
 ------------------------------------------------------------------------------ */
export enum DeliveryStatus {
  /** 상품준비중 */
  PRODUCT_PREPARING = "PRODUCT_PREPARING",
  /** 배송중 */
  SHIPPING_ONGOING = "SHIPPING_ONGOING",
  /** 배송완료 */
  SHIPPING_COMPLETE = "SHIPPING_COMPLETE",
}

/**
 * 프론트에서 쓰기쉽게
 */
export const DeliveryStatusLabelMap = {
  [OrderStatus.PRODUCT_PREPARING]: "상품준비중",
  [OrderStatus.SHIPPING_ONGOING]: "배송중",
  [OrderStatus.SHIPPING_COMPLETE]: "배송완료",
};
export const deliveryStatusList = Object.entries(DeliveryStatusLabelMap).map(
  ([value, label]) => ({
    label,
    value: value as DeliveryStatus,
  })
);

// 어드민 스토어관리 -> 상품관리 라벨링
export const ageOptions = [
  { value: "", label: "선택" },
  { value: "10대 미만", label: "10대 미만" },
  { value: "10대", label: "10대" },
  { value: "20대", label: "20대" },
  { value: "30대", label: "30대" },
  { value: "40대", label: "40대" },
  { value: "50대 이상", label: "50대 이상" },
];

export const relationOptions = [
  { value: "", label: "선택" },
  { value: "친구", label: "친구" },
  { value: "부모님", label: "부모님" },
  { value: "동료", label: "동료" },
  { value: "연인", label: "연인" },
  { value: "기타", label: "기타" },
];

export const typeOptions = [
  { value: "", label: "선택" },
  { value: "기념일", label: "기념일" },
  { value: "모임", label: "모임" },
  { value: "생일", label: "생일" },
  { value: "데이트", label: "데이트" },
  { value: "여행", label: "여행" },
  { value: "비즈니스", label: "비즈니스" },
  { value: "기타", label: "기타" },
];

export const genderOptions = [
  { value: "", label: "선택" },
  { value: "여자", label: "여자" },
  { value: "남자", label: "남자" },
  { value: "누구에게나", label: "누구에게나" },
];

export const categoryOptions = [
  { value: "", label: "선택" },
  { value: "센스's Pick", label: "센스's Pick" },
  { value: "이벤트", label: "이벤트" },
  { value: "럭셔리", label: "럭셔리" },
  { value: "뷰티", label: "뷰티" },
  { value: "인테리어", label: "인테리어" },
  { value: "의류", label: "의류" },
  { value: "패션잡화", label: "패션잡화" },
  { value: "아웃도어", label: "아웃도어" },
];

export const rankingOptions = [
  { value: "", label: "선택" },
  { value: "여자친구", label: "여자친구" },
  { value: "남자친구", label: "남자친구" },
  { value: "엄마", label: "엄마" },
  { value: "아빠", label: "아빠" },
  { value: "친구", label: "친구" },
  { value: "생일", label: "생일" },
  { value: "기념일", label: "기념일" },
  { value: "뷰티", label: "뷰티" },
  { value: "홈인테리어", label: "홈인테리어" },
  { value: "가전/디지털", label: "가전/디지털" },
  { value: "완구/취미/오피스", label: "완구/취미/오피스" },
];
