/** ------------------------------------------------------------------------------
 * 
 * python Django request common type
 * 
 ------------------------------------------------------------------------------ */
export interface DjangoListParams {
  page?: number;
  page_size?: number;
}

export enum Ordering {
  CREATED = "-created",
  CREATED_ASC = "created",
  POINT = "-point",
  VISIT_COUNT = "-visit_count",
  LIKE_COUNT = "-like_count",
  BEST = "-best",
  GRADE_HIGH = "-grade",
  GRADE_LOW = "grade",
  STATUS = "status",
}

export interface DjangoSearchOrderingParams extends DjangoListParams {
  ordering?: Ordering;
  search?: string;
}
