import React from "react"
import { cn } from "@runners/lib/utils"

type SwitchPropsBase = Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">

export interface SwitchProps extends SwitchPropsBase {
  // size는 높이기준입니다!
  size: "20" | "22" | "24" | "26" | "28" | "29"
}

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  ({ size = "24", name, checked, disabled, className, ...props }: SwitchProps, ref) => {
    const height = Number(size)
    const width = height * 2

    return (
      <label
        style={{ width, height, borderRadius: height, padding: width / 16 }}
        className={cn("relative flex cursor-pointer transition-colors duration-200 ease-in-out", {
          "bg-primary-600": checked,
          "bg-gray-300": !checked,
          "bg-gray-200": disabled,
        })}
        htmlFor={name}
      >
        <input
          type="checkbox"
          ref={ref}
          name={name}
          id={name}
          className="hidden"
          checked={checked}
          disabled={disabled}
          {...props}
        />
        <div
          style={{ width: width * 0.375, height: width * 0.375 }}
          className={cn(
            "rounded-full bg-white transition-transform duration-200 ease-in-out",
            {
              "transform translate-x-[133%]": checked,
              "transform translate-x-0": !checked,
            },
            className,
          )}
        />
      </label>
    )
  },
)

Switch.displayName = "Switch"

export default Switch
