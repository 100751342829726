import { useQuery } from "@tanstack/react-query"
import productQueryKey from "@/_api/_query-key/product"
import productLabelQueryKey from "@/_api/_query-key/product-label"
import productApi from "@/_api/product"
import productLabelApi from "@/_api/product-label"
import { AdminProductLabelListParams } from "@/_api/product-label-type"
import { AdminProductListParams } from "@/_api/product.type"
import { useInfiniteData } from "@/_hooks/use-infinite-data"

export const useGetProductListQuery = (params: AdminProductListParams) => {
  return useInfiniteData({
    queryKey: productQueryKey.productList(params),
    queryFn: () => productApi.getProductList(params),
  })
}

export const useGetProductLabelListQuery = (params: AdminProductLabelListParams) => {
  return useQuery({
    queryKey: productLabelQueryKey.productLabelList(params),
    queryFn: () => productLabelApi.getProductLabelList(params),
    select: response => response.data,
  })
}
