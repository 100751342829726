import { useFormContext, useWatch } from "react-hook-form"
import { BannerDisplayType } from "../_constants"
import AdminThumbnailFields from "./more-fields/admin-thumbnail-fields"
import AdminContentDetailBand from "./more-fields/content/detail/admin-content-detail-band-fields"
import AdminHomeButtonFields from "./more-fields/home/admin-home-button-fields"
import AdminHomeObject from "./more-fields/home/admin-home-object-fields"
import AdminHomeThumnailFields from "./more-fields/home/admin-home-thumnail-fields"
import AdminProductDetailBandFields from "./more-fields/product/detail/admin-product-detail-band-fields"
import AdminProductDetailCardFields from "./more-fields/product/detail/admin-product-detail-card-fields"
import AdminShoppingImageFields from "./more-fields/shopping/admin-shopping-image-fields"
import AdminStoryCardFields from "./more-fields/story/admin-story-card-fields"
import AdminStoryDetailCardFields from "./more-fields/story/detail/admin-story-detail-card-fields"
import AdminStoryDetailCardLongFields from "./more-fields/story/detail/admin-story-detail-card-long-fields"
import AdminStoryDetailCardShortFields from "./more-fields/story/detail/admin-story-detail-card-short-fields"
import AdminStoryDetailMobileBandFileds from "./more-fields/story/detail/mobile/admin-story-detail-mobile-band-fileds"

const FieldsComponent: { [key in BannerDisplayType]: ((props: any) => JSX.Element) | null } = {
  HOME_OBJECT: AdminHomeObject,
  HOME_THUMBNAIL: AdminHomeThumnailFields,
  HOME_BUTTON: AdminHomeButtonFields,
  CONTENT_DETAIL_BAND: AdminContentDetailBand,
  STORY_CARD_TOP: AdminStoryCardFields,
  STORY_CARD_BOTTOM: AdminStoryCardFields,
  STORY_DETAIL_CARD: AdminStoryDetailCardFields,
  STORY_DETAIL_CARD_SHORT: AdminStoryDetailCardShortFields,
  STORY_DETAIL_CARD_LONG: AdminStoryDetailCardLongFields,
  STORY_DETAIL_MOBILE_BAND: AdminStoryDetailMobileBandFileds,
  PRODUCT_DETAIL_CARD: AdminProductDetailCardFields,
  PRODUCT_DETAIL_BAND: AdminProductDetailBandFields,
  SHOPPING_IMAGE: AdminShoppingImageFields,
  RANKING_TOP_BANNER: AdminThumbnailFields,

  POST: AdminThumbnailFields,
  SHOPPING: AdminThumbnailFields,
  STORE: AdminThumbnailFields,
}

const AdminBannerDisplayFields = () => {
  const { control } = useFormContext()
  const displayType: { value: BannerDisplayType; label: string } = useWatch({
    name: "display_type",
    control,
  })

  if (!displayType) return null

  const SelectedComponent = FieldsComponent[displayType.value]

  return SelectedComponent ? (
    <div key={`${displayType.value}`} className="flex flex-col gap-4">
      <SelectedComponent />
    </div>
  ) : null
}

export default AdminBannerDisplayFields
