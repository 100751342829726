import { useFormContext } from "react-hook-form"
import AdminLabel from "../admin-guide-label"

const AdminGuideCategory = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-5">
        <AdminLabel required>카테고리</AdminLabel>
        <div className="w-full">
          <select
            {...register("sub_type", { required: "카테고리를 선택해주세요." })}
            className="w-[200px] rounded border p-3 shadow-sm focus:outline-none"
          >
            <option value="" disabled>
              선택해 주세요
            </option>
            <option value="COMMON_QUESTION">자주 묻는 질문</option>
            <option value="SETTLEMENT">정산</option>
            <option value="ORDER_CANCEL">주문/취소</option>
            <option value="PRODUCT">상품</option>
            <option value="SELLING_GUIDE">판매 가이드</option>
          </select>
        </div>
      </div>
      {errors.sub_type && <p className="mt-1 text-sm text-red-500">{errors.sub_type.message as string}</p>}
    </div>
  )
}

export default AdminGuideCategory
