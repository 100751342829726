import { DjangoListResponse } from "@runners/interface"
import api, { METHOD } from "."
import { AdminProductLabelListDto, AdminProductLabelListParams } from "./product-label-type"

const productLabelApi = {
  getProductLabelList: async (params: AdminProductLabelListParams) => {
    const { data } = await api<DjangoListResponse<AdminProductLabelListDto>>(`/admin/product-labels`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  PostProductLabelList: async ({
    product_ids,
    product_labels,
  }: {
    product_ids: number[]
    product_labels: number[]
  }) => {
    const { data } = await api(`/admin/product/label`, {
      method: METHOD.POST,
      data: { product_ids, product_labels },
    })
    return data
  },
}

export default productLabelApi
