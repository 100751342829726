import { MouseEvent, useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { CalendarIcon, ClockIcon } from "@runners/editor/src/icons"
import { FormInput } from "@runners/ui"
import CalendarModal from "@/_components/modal/calendar-modal"
import { useRelativeModal } from "@/_zustand/admin-relative-portal"
import AdminLabel from "../admin-notice-label"
import TimePickerModal from "../modal/admin-notice-time-picker"

const AdminNoticeStartDate = () => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const [date, setDate] = useState("")
  const [time, setTime] = useState("")

  const startDate = watch("start_date")
  const calendarRef = useRef<HTMLInputElement>(null)
  const timeRef = useRef<HTMLInputElement>(null)
  const relative = useRelativeModal()

  const openCalendarModal = (e: MouseEvent<HTMLInputElement>) => {
    relative.open({
      Component: CalendarModal,
      componentProps: {
        onClick: (selectedDate: string) => {
          setDate(selectedDate)
          setValue("start_date", `${selectedDate} ${time}`.trim())
          relative.clear()
        },
      },
      targetRef: e.currentTarget,
    })
  }

  const openTimePickerModal = (e: MouseEvent<HTMLInputElement>) => {
    relative.open({
      Component: TimePickerModal,
      componentProps: {
        onClick: (selectedTime: string) => {
          setTime(selectedTime)
          setValue("start_date", `${date} ${selectedTime}`.trim())
          relative.clear()
        },
      },
      targetRef: e.currentTarget,
    })
  }

  useEffect(() => {
    if (startDate) {
      const [initialDate, initialTime] = startDate.split(" ")
      setDate(initialDate || "")
      setTime(initialTime || "")
    }
  }, [startDate])

  return (
    <div className="flex gap-5">
      <AdminLabel required>시작 일자</AdminLabel>
      {/* 날짜 입력 필드 */}
      <FormInput
        name="start_date_date"
        className="w-full"
        readOnly
        ref={calendarRef}
        errors={errors}
        onClick={openCalendarModal}
        placeholder="날짜 선택"
        value={date}
        suffix={
          <CalendarIcon
            onClick={e => {
              e.preventDefault()
              calendarRef.current?.click()
            }}
            className="size-5 hover:cursor-pointer"
          />
        }
      />
      {/* 시간 입력 필드 */}
      <FormInput
        name="start_date_time"
        className="w-full"
        readOnly
        ref={timeRef}
        errors={errors}
        onClick={openTimePickerModal}
        placeholder="시간 선택"
        value={time}
        suffix={
          <ClockIcon
            onClick={e => {
              e.preventDefault()
              timeRef.current?.click()
            }}
            className="size-5 hover:cursor-pointer"
          />
        }
      />
    </div>
  )
}

export default AdminNoticeStartDate
